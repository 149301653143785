.about-content {
    text-align: left;

    ul.key-list {
        margin-bottom: 50px;

        li {
            margin-bottom: 10px;
        }
    }
}
.about-image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 60px;

    img {
        max-width: 500px;
    }
}