/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
  padding: 0 !important;
}

a {
  color: #{$color-accent};
  text-decoration: none;
}

a:hover {
  color: #{$color-primary};
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

.meta-info {
  font-size: 14px;
  margin-bottom: 30px !important;
}

.no-backdrop {
  opacity: 0 !important;
}

/*--------------------------------------------------------------
  # Layout
  --------------------------------------------------------------*/
.page-content {
  margin: 70px 0;

  .highlighted {
    font-weight: 600;
    color: #222;
  }

  a.highlighted {
    font-weight: 600;
    color: #{$color-primary};
  }

  h2.section-header {
    font-size: 26px;
    margin-top: 50px;
  }

  p {
    max-width: 960px;
    margin: auto;
    padding-top: 15px;
    color: #666;
  }
  h1, h2, h3, h4, h5, h6 {
    max-width: 960px;
    margin: auto;
    color: #{$color-primary};
    font-weight: 600;
  }

  h3 {
    font-size: 20px;
    margin-top: 20px;
    text-transform: capitalize;
  }

  ul {
    max-width: 960px;
    margin: auto;
  }

  &.document {
    margin: 125px 0 70px 0;

    h2 {
      font-size: 24px;
      margin-top: 25px;
    }
    h3 {
      font-size: 20px;
      margin-top: 20px;
    }
    p {
      padding-top: 10px;
    }
    a {
      color: #{$color-primary};
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .page-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #{$color-primary-700};
    }

    h1 {
      font-weight: bold;
      font-family: "Exo", sans-serif;
      color: #{$color-primary};
      text-align: center;
      position: relative;
      z-index: 9;
      margin: 40px 0;
    }

    &.has-image {
      height: 250px;
      margin-bottom: 50px;

      h1 {
        color: #FFF;
      }
    }
  }
}

ul.key-list {
  margin-top: 25px;
}
ul.key-list li {
  margin-bottom: 20px;
}
ul.key-list .title {
  font-size: 18px;
  color: #{$color-primary};
  font-weight: 600;
  margin-bottom: 0;
}
ul.key-list .description {
  font-size: 16px;
  padding-top: 8px;
}

article h4 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px !important;
}
article p {
  padding-top: 6px !important;
}

article.highlighted {
  position: relative;
  background: #9ffcd0;
  max-width: 960px;
  margin: 30px auto 50px auto;
  padding: 50px 30px;
}
article.highlighted p {
  color: #222;
  font-weight: normal;
}
article.highlighted:before {
  position: absolute;
  content: "";
  display: block;
  background: linear-gradient(45deg, #9ffcd0 25%, transparent 25%), linear-gradient(-45deg, #9ffcd0 25%, transparent 25%);
  background-size: 16px 16px;
  height: 16px;
  width: 100%;
  top: -16px;
  left: 0;
}
article.highlighted:after {
  position: absolute;
  content: "";
  display: block;
  background: linear-gradient(135deg, #9ffcd0 25%, transparent 25%), linear-gradient(-135deg, #9ffcd0 25%, transparent 25%);
  background-size: 16px 16px;
  height: 16px;
  width: 100%;
  bottom: -16px;
  left: 0;
}

article.highlighted p {
  font-size: 16px;
  padding: 12px 0 !important;
}

/*~~~~~~~~~~~~~~ Document ~~~~~~~~~~~~~~~~~*/
article.document {
  max-width: 960px;
  margin: auto;
}
article.document h1,
article.document h2,
article.document h3,
article.document h4,
article.document p,
article.document ul li {
  font-family: 'Open Sans', sans-serif;
  color: #333;
}


/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
section {
  padding: 100px 0 50px 0;
  overflow: hidden;

  h2.section-header {
    position: relative;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 65px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #{$color-primary};
  }

  
  h2.section-header:after {
    content: " ";
    border-bottom: 2px solid #009684;
    width: 60px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  p.overview {
    text-align: center;
    margin-bottom: 50px;
    max-width: 800px;
    margin: -20px auto 50px auto;
  }

  h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #{$color-primary};
  }

  .title-with-icon {
    .icon {
      margin: 0 15px 6px 0;
    }
  }
}


@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #012970;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 57px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: "/";
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*~~~~~~~~~~~~~~~~~ Sweet Alert ~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.swal2-modal {
  padding-bottom: 20px !important;

  .swal2-title {
      font-size: 1.5em !important;
      font-weight: normal !important;
  }

  .swal2-content {
      padding: 10px 0 !important;
      font-size: 1em !important;
      line-height: 1.5em !important;
  }

  .swal2-actions {
      button {
          padding: 10px 30px !important;
          &.swal2-confirm {
            background-color: #{$color-primary} !important;
          }
      }
  }
}

/* ~~~~~~~~~~~~~~~~ Modal ~~~~~~~~~~~~~~~~~~~~~~~ */
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: rgba(40, 44, 49, 0.4);
  padding: 0;
  z-index: 999;
  animation: fadeIn 0.4s 1;
  overflow-y: auto;
}

.modal-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: calc(100% - 16px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
  max-width: 600px;
  animation: fadeInDown 0.4s ease-in-out;
  transition: all .4s ease-in-out;

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9;
  }

  .modal-header {
    border: 0;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $primary;
    width: 100%;
    justify-content: center;

    .modal-title {
      margin-top: 10px;
    }
  }

  .modal-loader-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    padding: 0 20px 20px 20px;
  }
}

.modal-email-status {
  min-height: 320px;

  .modal-header {
    border-bottom: 1px solid #{$color-primary};
  }

  .modal-email-status-content {
    display: flex;
    padding: 0 20px;

    .col-md-4,
    .col-md-8,
    .col-md-12 {
      padding: 0 10px;
    }

    .mdi-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;
      font-size: 20px;
      cursor: pointer;
    }

    .title-email-address {
      font-weight: 500;
      background: #00b38a2b;
      color: #00b38a;
      padding: 8px 15px;
      border-radius: 4px;
      font-size: 15px;
      margin: 20px auto 25px auto;
      text-align: center;
    }

    .email-status-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .email-status-info-label {
        font-size: 10px;
        text-transform: uppercase;
        color: #666;
        text-align: left;
        letter-spacing: 1px;
      }

      .email-status-info-value {
        margin-top: 8px;
        background: #F8F8F8;
        border: 1px solid #EEE;
        padding: 4px 10px;
        text-align: left;
        border-radius: 4px;
        font-size: 14px;
        min-height: 26px;
      }
    }
  }

  .email-status-valid {
    color: #{$status-valid-text} !important;
    background: #{$status-valid-text-background} !important;
  }

  .email-status-unknown {
    color: #{$status-unknown-text} !important;
    background: #{$status-unknown-text-background} !important;
  }

  .email-status-invalid-l0 {
    color: #{$status-invalid-l0-text} !important;
    background: #{$status-invalid-l0-text-background} !important;
  }

  .email-status-invalid-l1 {
    color: #{$status-invalid-l1-text} !important;
    background: #{$status-invalid-l1-text-background} !important;
  }
}


/*~~~~~~~~~~~~~~~~~ Loader ~~~~~~~~~~~~~~~~~~~~~~ */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #{$color-primary};
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}