.footer {
    background: #222;
    color: #CCC;
    font-size: 14px;

    .footer-top {
        background: #333;
        padding: 60px 0 30px 0;

        .brand-logo {
            margin-bottom: 30px;
        }

        @media (max-width: 992px) {
            background-position: center bottom;
        }

        .footer-info {
            margin-bottom: 30px;

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                font-family: "Nunito", sans-serif;
            }
        }

        .social-links a {
            font-size: 20px;
            display: inline-block;
            color: rgba(1, 41, 112, 0.5);
            line-height: 0;
            margin-right: 10px;
            transition: 0.3s;

            &:hover {
                color: #012970;
            }
        }

        h4 {
            font-size: 16px;
            font-weight: bold;
            color: #FFF;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 12px;
        }

        .footer-links {
            margin-bottom: 30px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                i {
                    padding-right: 2px;
                    color: #d0d4fc;
                    font-size: 12px;
                    line-height: 0;
                }

                li {
                    padding: 6px 0;
                    display: flex;
                    align-items: center;
                    font-size: 13px;

                    &:first-child {
                        padding-top: 0;
                    }

                    a {
                        color: #CCC;
                        transition: 0.3s;
                        display: inline-block;
                        line-height: 1;

                        &:hover {
                            color: #{$color-primary};
                        }
                    }
                }
            }
        }

        .footer-contact p {
            line-height: 26px;
        }
    }

    .footer-bottom {
        display: flex;
        .container {
            display: flex;
            padding: 30px 0;
        }

        .copyright {
            display: flex;
            flex: 1;
            color: #AAA;
        }

        .links ul {
            list-style: none;

            li {
                float: right;

                a {
                    display: inline-block;
                    margin-left: 10px;
                    color: #AAA;

                    &:hover {
                        color: #{$color-primary};
                    }
                }
            }
        }

        @media screen and (max-width: 991px) {
            .container {
                flex-direction: column;
                align-items: center;
            }

            .links ul {
                column-count: 2;
                margin: 20px 0 0 0;
                padding: 0;
                li {
                    float: none;
                }
            }
        }
    }
}