#how-it-works {
    padding-top: 150px;
}

/*--------------------------------------------------------------
# Why Choose Us
--------------------------------------------------------------*/
#why-choose-us {
    .section-header {
        margin-bottom: 50px;
    }
    .icon {
        color: #{$orange};
    }

    p {
        padding-left: 35px
    }

    img {
        padding-left: 30px;
        margin-top: -30px;
    }
}

#key-features {
    .icon-box {
        display: flex;

        .icon {
            min-width: 70px;
            max-width: 70px;
            color: #{$orange};
        }
    }
}

#benefits {
    .icon-box {
        display: flex;

        .icon {
            width: 125px;
            color: #{$green};
        }
    }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    .content {
        background-color: #f6f9ff;
        padding: 40px;

        h3 {
            font-size: 14px;
            font-weight: 700;
            color: #4154f1;
            text-transform: uppercase;
        }

        h2 {
            font-size: 24px;
            font-weight: 700;
            color: #012970;
        }

        p {
            margin: 15px 0 30px 0;
            line-height: 24px;
        }

        .btn-read-more {
            line-height: 0;
            padding: 15px 40px;
            border-radius: 4px;
            transition: 0.5s;
            color: #fff;
            background: #4154f1;
            box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);

            span {
                font-family: "Nunito", sans-serif;
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 1px;
            }

            i {
                margin-left: 5px;
                font-size: 18px;
                transition: 0.3s;

                &:hover {
                    transform: translateX(5px);
                }
            }
        }
    }
}

/*--------------------------------------------------------------
  # Values
  --------------------------------------------------------------*/
.values {
    .box {
        padding: 30px;
        box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
        text-align: center;
        transition: 0.3s;
        height: 100%;

        img {
            padding: 30px 50px;
            transition: 0.5s;
            transform: scale(1.1);
        }

        h3 {
            font-size: 24px;
            color: #012970;
            font-weight: 700;
            margin-bottom: 18px;
        }

        &:hover {
            box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);

            img {
                transform: scale(1);
            }
        }
    }
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
    padding: 70px 0 60px;

    .count-box {
        display: flex;
        align-items: center;
        padding: 30px;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);

        i {
            font-size: 42px;
            line-height: 0;
            margin-right: 20px;
            color: #4154f1;
        }

        span {
            font-size: 36px;
            display: block;
            font-weight: 600;
            color: #0b198f;
        }

        p {
            padding: 0;
            margin: 0;
            font-family: "Nunito", sans-serif;
            font-size: 14px;
        }
    }
}


/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
    .feature-box {
        padding: 24px 20px;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
        transition: 0.3s;
        height: 100%;

        h3 {
            font-size: 18px;
            color: #012970;
            font-weight: 700;
            margin: 0;
        }

        i {
            line-height: 0;
            background: #ecf3ff;
            padding: 4px;
            margin-right: 10px;
            font-size: 24px;
            border-radius: 3px;
            transition: 0.3s;

            &:hover {
                background: #4154f1;
                color: #fff;
            }
        }
    }

    .feture-tabs {
        margin-top: 120px;

        h3 {
            color: #012970;
            font-weight: 700;
            font-size: 32px;
            margin-bottom: 10px;

            @media (max-width: 768px) {
                font-size: 28px;
            }
        }

        .nav-pills {
            border-bottom: 1px solid #eee;

            .nav-link {
                background: none;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 600;
                color: #012970;
                padding: 12px 0;
                margin-right: 25px;
                margin-bottom: -2px;
                border-radius: 0;

                &.active {
                    color: #4154f1;
                    border-bottom: 3px solid #4154f1;
                }
            }
        }

        .tab-content {
            h4 {
                font-size: 18px;
                margin: 0;
                font-weight: 700;
                color: #012970;
            }

            i {
                font-size: 24px;
                line-height: 0;
                margin-right: 8px;
                color: #4154f1;
            }
        }
    }

    .feature-icons {
        margin-top: 120px;

        h3 {
            color: #012970;
            font-weight: 700;
            font-size: 32px;
            margin-bottom: 20px;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 28px;
            }
        }

        .content {
            .icon-box {
                display: flex;

                h4 {
                    font-size: 20px;
                    font-weight: 700;
                    margin: 0 0 10px 0;
                    color: #012970;
                }

                i {
                    font-size: 44px;
                    line-height: 44px;
                    color: #0245bc;
                    margin-right: 15px;
                }

                p {
                    font-size: 15px;
                    color: #848484;
                }
            }
        }
    }
}


/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
    .service-box {
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
        height: 100%;
        padding: 50px 30px 30px 30px;
        text-align: center;
        transition: 0.3s;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #00b38a;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            line-height: 0;
            transition: 0.3s;

            svg {
                width: 48px;
                height: 48px;
                fill: #{$aqua-light};
            }
        }

        h3 {
            color: #FFF;
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 15px;
        }

        p {
            color: #fff;
        }

        .step-badge {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background: #00b38a;
            top: -30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 32px;
            font-weight: bold;
            color: #{$aqua-light};
            padding-bottom: 7px;
        }

        .read-more {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 16px;
            padding: 8px 20px;

            i {
                line-height: 0;
                margin-left: 5px;
                font-size: 18px;
            }
        }
    }
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
    .accordion-collapse {
        border: 0;
    }

    .accordion-button {
        padding: 15px 15px 20px 0;
        font-weight: 600;
        border: 0;
        font-size: 18px;
        color: #444444;
        text-align: left;

        &:focus {
            box-shadow: none;
        }

        &:not(.collapsed) {
            background: none;
            color: #{$color-primary};
            border-bottom: 0;
        }
    }

    .accordion-body {
        padding: 10px 0 25px 0;
        border: 0;
    }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {
    #portfolio-flters {
        padding: 0;
        margin: 0 auto 25px auto;
        list-style: none;
        text-align: center;
        background: white;
        border-radius: 50px;
        padding: 2px 15px;
    }

    #portfolio-flters li {
        cursor: pointer;
        display: inline-block;
        padding: 8px 20px 10px 20px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        color: #444444;
        margin: 0 4px 8px 4px;
        transition: 0.3s;
        border-radius: 50px;
        border: 1px solid #fff;

        &:hover,
        &.filter-active {
            color: #4154f1;
            border-color: #4154f1;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .portfolio-wrap {
        transition: 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        background: rgba(255, 255, 255, 0.75);

        &::before {
            content: "";
            background: rgba(255, 255, 255, 0.75);
            position: absolute;
            left: 30px;
            right: 30px;
            top: 30px;
            bottom: 30px;
            transition: all ease-in-out 0.3s;
            z-index: 2;
            opacity: 0;
        }

        img {
            transition: 1s;
        }

        .portfolio-info {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            z-index: 3;
            transition: all ease-in-out 0.3s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &::before {
                display: block;
                content: "";
                width: 48px;
                height: 48px;
                position: absolute;
                top: 35px;
                left: 35px;
                border-top: 3px solid rgba(1, 41, 112, 0.2);
                border-left: 3px solid rgba(1, 41, 112, 0.2);
                transition: all 0.5s ease 0s;
                z-index: 9994;
            }

            &::after {
                display: block;
                content: "";
                width: 48px;
                height: 48px;
                position: absolute;
                bottom: 35px;
                right: 35px;
                border-bottom: 3px solid rgba(1, 41, 112, 0.2);
                border-right: 3px solid rgba(1, 41, 112, 0.2);
                transition: all 0.5s ease 0s;
                z-index: 9994;
            }

            h4 {
                font-size: 20px;
                color: #012970;
                font-weight: 700;
            }

            p {
                color: #012970;
                font-weight: 600;
                font-size: 14px;
                text-transform: uppercase;
                padding: 0;
                margin: 0;
            }
        }

        .portfolio-links {
            text-align: center;
            z-index: 4;

            a {
                color: #fff;
                background: #4154f1;
                margin: 10px 2px;
                width: 36px;
                height: 36px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: 0.3s;

                i {
                    font-size: 24px;
                    line-height: 0;
                }

                &:hover {
                    background: #5969f3;
                }
            }
        }

        &:hover img {
            transform: scale(1.1);
        }

        &:hover::before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }

        &:hover .portfolio-info {
            opacity: 1;

            &::before {
                top: 15px;
                left: 15px;
            }

            &::after {
                bottom: 15px;
                right: 15px;
            }
        }
    }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;

    .portfolio-details-slider {
        img {
            width: 100%;
        }

        .swiper-pagination {
            margin-top: 20px;
            position: relative;

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                background-color: #fff;
                opacity: 1;
                border: 1px solid #4154f1;

                &-active {
                    background-color: #4154f1;
                }
            }
        }

        .portfolio-info {
            padding: 30px;
            box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);

            h3 {
                font-size: 22px;
                font-weight: 700;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #eee;
            }

            ul {
                list-style: none;
                padding: 0;
                font-size: 15px;

                li+li {
                    margin-top: 10px;
                }
            }
        }

        .portfolio-description {
            padding-top: 30px;

            h2 {
                font-size: 26px;
                font-weight: 700;
                margin-bottom: 20px;
            }

            p {
                padding: 0;
            }
        }
    }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
    .testimonial-item {
        box-sizing: content-box;
        padding: 30px;
        margin: 40px 30px;
        box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
        background: #fff;
        min-height: 320px;
        display: flex;
        flex-direction: column;
        text-align: center;
        transition: 0.3s;

        .stars {
            margin-bottom: 15px;

            i {
                color: #ffc107;
                margin: 0 1px;
            }
        }

        .testimonial-img {
            width: 90px;
            border-radius: 50%;
            border: 4px solid #fff;
            margin: 0 auto;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin: 10px 0 5px 0;
            color: #111;
        }

        h4 {
            font-size: 14px;
            color: #999;
            margin: 0;
        }

        p {
            font-style: italic;
            margin: 0 auto 15px auto;
        }
    }

    .swiper-pagination {
        margin-top: 20px;
        position: relative;

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background-color: #fff;
            opacity: 1;
            border: 1px solid #4154f1;

            &-active {
                background-color: #4154f1;
            }
        }
    }

    .swiper-slide {
        opacity: 0.3;
    }

    @media (max-width: 1199px) {
        .swiper-slide-active {
            opacity: 1;
        }

        .swiper-pagination {
            margin-top: 0;
        }
    }

    @media (min-width: 1200px) {
        .swiper-slide-next {
            opacity: 1;
            transform: scale(1.12);
        }
    }
}

// Team
.team {
    background: #fff;
    padding: 60px 0;

    .member {
        overflow: hidden;
        text-align: center;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
        transition: 0.3s;

        .member-img {
            position: relative;
            overflow: hidden;

            &:after {
                position: absolute;
                content: "";
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                background: url(../img/team-shape.svg) no-repeat center bottom;
                background-size: contain;
                z-index: 1;
            }
        }

        .social {
            position: absolute;
            right: -100%;
            top: 30px;
            opacity: 0;
            border-radius: 4px;
            transition: 0.5s;
            background: rgba(255, 255, 255, 0.3);
            z-index: 2;

            a {
                transition: color 0.3s;
                color: rgba(1, 41, 112, 0.5);
                margin: 15px 12px;
                display: block;
                line-height: 0;
                text-align: center;

                &:hover {
                    color: rgba(1, 41, 112, 0.8);
                }

                i {
                    font-size: 18px;
                }
            }
        }

        .member-info {
            padding: 10px 15px 20px 15px;

            h4 {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 20px;
                color: #012970;
            }

            span {
                display: block;
                font-size: 14px;
                font-weight: 400;
                color: #aaaaaa;
            }

            p {
                font-style: italic;
                font-size: 14px;
                padding-top: 15px;
                line-height: 26px;
                color: #5e5e5e;
            }
        }

        &:hover {
            transform: scale(1.08);
            box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
        }

        &:hover .social {
            right: 8px;
            opacity: 1;
        }
    }
}

// Clients
.clients {
    .clients-slider {
        .swiper-slide img {
            opacity: 0.5;
            transition: 0.3s;

            &:hover {
                opacity: 1;
            }
        }

        .swiper-pagination {
            margin-top: 20px;
            position: relative;

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                background-color: #fff;
                opacity: 1;
                border: 1px solid #4154f1;

                &-active {
                    background-color: #4154f1;
                }
            }
        }
    }
}

// Recent Blog Posts
.recent-blog-posts {
    .post-box {
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
        transition: 0.3s;
        height: 100%;
        overflow: hidden;
        padding: 30px;
        border-radius: 8px;
        position: relative;
        display: flex;
        flex-direction: column;

        .post-img {
            overflow: hidden;
            margin: -30px -30px 15px -30px;
            position: relative;

            img {
                transition: 0.5s;
            }
        }

        .post-date {
            font-size: 16px;
            font-weight: 600;
            color: rgba(1, 41, 112, 0.6);
            display: block;
            margin-bottom: 10px;
        }

        .post-title {
            font-size: 24px;
            color: #012970;
            font-weight: 700;
            margin-bottom: 18px;
            position: relative;
            transition: 0.3s;

            &:hover {
                color: #4154f1;
            }
        }

        .readmore {
            display: flex;
            align-items: center;
            font-weight: 600;
            line-height: 1;
            transition: 0.3s;

            i {
                line-height: 0;
                margin-left: 4px;
                font-size: 18px;
            }
        }

        &:hover {
            .post-title {
                color: #4154f1;
            }

            .post-img img {
                transform: rotate(6deg) scale(1.2);
            }
        }
    }
}

/*~~~~~~~~~~~~~~~~ Pre launch offer modal ~~~~~~~~~~~~~~~~~~*/
#pre-launch-offer-modal {
    position: relative;
    max-width: 600px;

    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .modal-content {
        border: 0;
        padding: 40px 20px;
        box-shadow: 0px 0px 14px rgba(0,0,0,.4);
        // background: #9ffcd0;
        // background: #FFF;
        background: url('../img/abstract-background.jpg');

        .offer-tag {
            text-align: center;
            font-family: 'Open Sans';
            font-weight: bold;
            color: #d3a82f;
            margin-bottom: -10px;
        }
        .offer-title {
            font-family: 'Open Sans';
            font-weight: 800;
            font-size: 60px;
            text-align: center;
            color: #009d79;
        }
        .offer-description {
            color: #444;
            font-size: 15px;
            text-align: center;
            padding: 25px 0;
            font-family: 'Open Sans';
            font-weight: 500;
        }

        .timer {
            padding: 25px 0;
            display: flex;
            flex-direction: column;
            
            .values-row, .labels-row {
                display: flex;
                margin-bottom: 10px;
            }
            .separator {
                max-width: 30px;
            }

            .values-row .value-card,
            .labels-row .label {
                display: flex;
                flex: 1;
                justify-content: center;
            }

            .values-row .value {
                color: #FFF;
                background: #00b38a;
                border-radius: 6px;
                text-align: center;
            }
            .separator {
                text-align: center;
                padding: 15px 0;
                color: #00b38a;
            }

            .values-row .value,
            .separator {
                font-family: 'Open Sans';
                font-weight: 700;
            }

            .labels-row .label {
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 500;
            }
        }

        .btn-get-started {
            background: #009d79;
            color: #FFF;
            cursor: pointer;
            margin-bottom: 0;
        }
        .btn-get-started:hover {
            background: #00b38a;
            color: #FFF;
        }
    }

    @media screen and (min-width: 768px){
        .values-row {
            .value, .separator {
                font-size: 44px;
                width: 80px;
                padding: 15px 10px;
            }
        }
        .separator {
            width: 30px;
        }
    }

    @media screen and (max-width: 767px){
        .modal-content {
            padding: 20px 10px;

            .offer-title {
                font-size: 45px;
            }
            .offer-description {
                padding: 15px 0;
            }

            .values-row {
                .value, .separator {
                    font-size: 32px;
                    width: 60px;
                    padding: 15px 0;
                }

            }

            .separator {
                width: 20px;
            }

            .btn-get-started {
                margin: 0;
            }
        }
    }
}