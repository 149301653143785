/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
    h2 {
        margin-bottom: 30px;
    }

    .info-box {
        color: #444444;
        background: #{$color-primary-100};
        padding: 30px;

        svg {
            line-height: 0;
            color: #{$color-primary};
        }

        h3 {
            font-size: 20px;
            color: #{$jungle-green};
            font-weight: 700;
            margin: 20px 0 10px 0;
        }

        p {
            padding: 0;
            line-height: 24px;
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    #contact-form {
        background: #{$color-primary-100};
        padding: 45px 30px;

        .error-message,
        .sent-message {
            display: none;
            color: #fff;
            padding: 15px;
            margin-bottom: 24px;
            font-weight: 600;
        }

        .error-message {
            background: #ed3c0d;
            text-align: left;
        }

        .sent-message {
            background: #18d26e;
            text-align: center;
        }

        .loading {
            display: none;
            background: #fff;
            text-align: center;
            padding: 15px;
            margin-bottom: 24px;

            &:before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin: 0 10px -6px 0;
                border: 3px solid #18d26e;
                border-top-color: #eee;
                animation: animate-loading 1s linear infinite;
            }
        }

        input,
        textarea {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;
            border-radius: 0;
            color: #222;

            &:focus {
                border-color: #{$color-primary};
            }
        }

        input[type="text"],
        input[type="email"] {
            padding: 10px 15px;
        }

        textarea {
            padding: 12px 15px;
        }

        select {
            padding: 10px 15px;
            width: 100%;
            border: 1px solid #dee2e6;
            background: #FFF;
            font-size: 14px;
            color: #222;
            &:focus {
                border-color: #{$color-primary};
            }
            outline: #{$color-primary};

            option {
                color: #666;
            }
            option:not(:first-of-type) {
                color: #222;
            }
        }

        button[type="submit"] {
            background: #{$color-primary};
            border: 0;
            padding: 10px 30px;
            margin-top: 15px;
            color: #fff;
            transition: 0.4s;
            border-radius: 4px;

            &:hover {
                background: #{$jungle-green};
            }
        }
    }

    @keyframes animate-loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}