/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    background: #{$color-navbar};

    &.with-bg {
        background: #{$color-primary};
    }

    &.header-scrolled {
        background: #fff;
        padding: 15px 0;
        box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    }

    .toggle-menu-container {
        z-index: 9999;
        width: 65px;
        height: 65px;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.brand-logo {
    display: flex;

    .logo-image {
        width: 54px;
        margin-right: 12px;

        img {
            max-width: 100%;
        }
    }

    .app-title {
        color: #f7c53a;
        font-family: 'Exo', sans-serif;
        font-weight: 600;
        font-size: 21px;
        letter-spacing: 1px;
    }

    .app-slogan {
        font-size: 9px;
        color: #FFF;
        font-weight: 400;
        letter-spacing: 0.45px;
    }
}

.menu-toggle {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 26px;
  
    span {
      margin: 0 auto;
      position: relative;
      top: 12px;
      transition-duration: 0s;
      transition-delay: 0.2s;
      transition: background-color 0.3s;
  
      &:before,
      &:after {
        position: absolute;
        content: '';
      }
  
      &, &:before, &:after {
        width: 26px;
        height: 2px;
        background-color: #FFF;
        display: block;
        opacity: 1;
      }
  
      &:before {
        margin-top: -8px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0;
      }
  
      &:after {
        margin-top: 8px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0;
      }
    }
  
    &.active {
      span {
        background-color: rgba(0, 0, 0, 0);
        transition: 0.3s background-color;
  
        &:before {
          margin-top: 0;
          transform: rotate(45deg);
          transition-delay: 0, 0.2s;
        }
  
        &:after {
          margin-top: 0;
          transform: rotate(-45deg);
          transition-delay: 0, 0.2s;
        }
      }
    }
  }

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0;

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;

        li {
            position: relative;
        }

        a,
        a:focus {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0 10px 30px;
            font-family: "Nunito", sans-serif;
            font-size: 16px;
            font-weight: 700;
            color: #{$color-navbar-text};
            white-space: nowrap;
            transition: 0.3s;

            i,
            &:focus i {
                font-size: 12px;
                line-height: 0;
                margin-left: 5px;
            }

            &:hover,
            .active,
            .active:focus,
            li:hover>a {
                color: #{$color-navbar-hover-text}
            }
        }

        .getstarted,
        .getstarted:focus {
            background: #{$aqua-light};
            padding: 8px 20px;
            margin-left: 30px;
            border-radius: 4px;
            color: #{$teal};

            &:hover,
            &:focus:hover {
                background: #{$color-navbar-hover-text};
                color: #{$color-primary};
            }
        }

        .dropdown {
            ul {
                display: block;
                position: absolute;
                left: 14px;
                top: calc(100% + 30px);
                margin: 0;
                padding: 10px 0;
                z-index: 99;
                opacity: 0;
                visibility: hidden;
                background: #fff;
                box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
                transition: 0.3s;
                border-radius: 4px;

                li {
                    min-width: 200px;
                }

                a {
                    padding: 10px 20px;
                    font-size: 15px;
                    text-transform: none;
                    font-weight: 600;

                    i {
                        font-size: 12px;
                    }

                    &:hover,
                    ul .active:hover,
                    li:hover>a {
                        color: #{$color-primary};
                    }
                }

                &:hover>ul {
                    opacity: 1;
                    top: 100%;
                    visibility: visible;
                }
            }

            &.megamenu {
                ul {
                    margin-top: 5px;
                    right: 0;
                    padding: 10px;
                    display: flex;

                    li {
                        flex: 1;

                        a,
                        &:hover>a {
                            color: #{$color-primary};
                        }

                        &:hover,
                        .active,
                        .active:hover {
                            color: #{$color-primary};
                        }
                    }
                }
            }

            .dropdown ul {
                top: 0;
                left: calc(100% - 30px);
                visibility: hidden;

                &:hover>ul {
                    opacity: 1;
                    top: 0;
                    left: 100%;
                    visibility: visible;
                }
            }

            @media (max-width: 1366px) {
                ul {
                    left: -90%;
                }

                &:hover>ul {
                    left: -100%;
                }
            }
        }
    }
}

/**
  * Mobile Navigation 
  */
.mobile-menu {
    background: #444;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    padding: 30px 0;
    z-index: 99;

    transition: right ease .5s;

    &.open {
        right: 0px;
        box-shadow: -4px 4px 20px rgba(0, 0, 0, .5);
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        flex-direction: column;

        li {
            a {
                padding: 15px 30px;
                width: 100%;

                &:hover {
                    background: #{$aqua-light};
                    color: #{$color-primary};
                }
            }
        }
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
    width: 100%;
    height: 100vh;
    // background: url(../img/hero-bg.png) top center no-repeat;
    background-size: cover;
    background: linear-gradient(180deg, #{$color-primary}, #{$color-primary-light});
    text-align: center;

    h1 {
        margin: 0;
        font-weight: 700;
        font-family: 'Exo', 'Open Sans', sans-serif;
        color: #FFF;
        text-transform: capitalize;

        .highlighted {
            color: #{$aqua-light};
        }
    }

    h2 {
        font-family: 'Open Sans', sans-serif;
        color: #fff;
        margin-top: 20px;
        max-width: 800px;
        margin: 30px auto 0 auto;
        line-height: 30px;
        font-size: 20px;

        &.highlighted {
            font-weight: 600;
            color: #e4ff83;
            margin-top: 50px;
        }
    }

    .hero-img {
        text-align: right;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        background-attachment: fixed;
        align-items: center;

        h1 {
            font-size: 50px;
            line-height: 70px;
        }

        h2 {
            font-size: 22px;

            &.highlighted {
                font-size: 20px;
                margin-top: 50px;
            }
        }

        .hero-img {
            img {
                width: 100%;
                margin-left: 40px;
            }
        }
    }

    @media (min-width: 1200px) {
        background-attachment: fixed;
        align-items: center;

        h1 {
            font-size: 60px;
            line-height: 80px;
        }

        h2 {
            font-size: 24px;

            &.highlighted {
                font-size: 20px;
                margin-top: 50px;
            }
        }

        .hero-img {
            img {
                width: 100%;
                margin-left: 40px;
            }
        }
    }

    @media (max-width: 991px) {
        height: 100vh;
        padding: 60px 0 25px 0;
        text-align: center;

        &>.container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 100px;
        }

        h1 {
            font-size: 50px;
            margin-top: 40px;
        }
        h2 {
            font-size: 20px;
            &.highlighted {
                margin-top: 15px;
                /*color: #00513b*/
            }
        }

        .hero-columns {
            display: flex;
            flex-direction: column-reverse;
        }

        .hero-img {
            text-align: center;
            margin-top: 50px;

            img {
                width: 70%;
            }
        }
    }

    @media (max-width: 767px) {
        padding: 100px 0 25px 0;
        text-align: center;

        &>.container {
            margin-bottom: 150px;
        }
        h1 {
            font-size: 32px;
        }
        h2 {
            font-size: 18px;
            &.highlighted {
                margin-top: 15px;
            }
        }

        .hero-img {
            height: 32vh;
            padding-top: 20px;
            margin: 0;

            img {
                width: 70%;
            }
        }

        .btn-get-started {
            margin-bottom: 10px;
        }
    }
}

@keyframes moving_icon {
    0% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

/*--------------------------------------------------------------
  # CTA Button
  --------------------------------------------------------------*/
.btn-get-started {
    margin: 40px 0;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #{$color-primary};
    background: #{$aqua-light};
    align-items: center;
    // box-shadow: 0px 2px 20px rgba(0, 150, 132, 0.6);

    span {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
    }

    svg {
        margin-left: 20px;
        animation: moving_icon 1s cubic-bezier(0.42, 0, 0.28, 1.23) 0s infinite alternate;
        -webkit-animation: moving_icon 1s cubic-bezier(0.42, 0, 0.28, 1.23) 0s infinite alternate;
    }

    &:hover {
        background: #FFF;

        svg {
            transition: all .4s;
            transform: translateX(10px);
        }
    }
}

/*--------------------------------------------------------------
  # Email Validator Bar
  --------------------------------------------------------------*/
  ::-ms-input-placeholder { /* Edge 12-18 */
    color: #{$color-primary};
  }
  ::-webkit-input-placeholder { /* Edge 12-18 */
    color: #{$color-primary};
  }
  #email-validator-bar {
    height: 100px;
    width: 80%;
    max-width: 1160px;
    background-color: #9ffcd0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin: auto;
    border-radius: 10px 10px 0 0;
  
    .col-lg-6 {
      display: flex;
      align-items: center;
  
      &:first-child {
        padding-right: 50px;
        justify-content: flex-end;
      }
    }
  
    h2 {
      text-align: right;
      font-size: 24px;
      color: #{$color-primary};
      margin: 0;
    }
  
    input {
      height: 36px;
      border: 0;
      margin: 0;
      outline: none;
      padding: 0 15px;
      border-radius: 4px;
      width: 100%;
      max-width: 400px;
      color: #{$color-primary};
      background: rgba(0,0,0,0.08);
    }
  
    button {
      border-radius: 4px;
      border: 0;
      height: 36px;
      padding: 0 10px;
      background: #{$color-primary};
      color: #FFF;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 991px) {
    #email-validator-bar {
        height: 120px;

        h2 {
            font-size: 20px;
            margin-bottom: 20px;
            text-align: center;
        }
    }
    
  }

  @media screen and (max-width: 767px) {
    #email-validator-bar {
        width: 95%;

        h2 {
            font-size: 20px;
            margin-bottom: 20px;
            text-align: center;
        }

        .input-field {
            display: flex;
        }
    }
    
  }