.pricing-page {

    p {
        max-width: 960px;
        margin: 0 auto;
    }

    .card {
        margin: 20px 0;
        border: 0;

        &.pricing {
            .card-title {
                text-align: center;
                margin-bottom: 60px;
                margin-top: 20px;
                font-size: 20px;
            }
        }
    }

    .pricing-box {
        .credits-rate {
            width: 360px;
            text-align: center;
            padding: 8px 0;

            p {
                margin-bottom: 8px;
                font-size: 14px;
                color: #777;
            }
        }

        .credits-preview {
            width: 380px;
            height: 100%;
            border-radius: 6px;
            box-shadow: 0px 2px 12px #CCC;
            padding: 40px;
            display: flex;
            flex-direction: column;

            .top-view {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;

                .purchase-amount {
                    font-size: 60px;
                    font-weight: 700;
                    color: #{$color-primary};

                    span {
                        font-size: 36px;
                        font-weight: 500;
                        display: inline-block;
                        vertical-align: top;
                        margin-top: 10px;

                        &:last-child {
                            vertical-align: bottom;
                            margin-bottom: 5px;
                            font-weight: bold;
                            font-size: 30px;
                        }
                    }
                }
            }

            .card-credits {
                padding: 12px 20px;
                margin-bottom: 30px;

                .text-label:first-child {
                    flex: 1;
                    text-align: left;
                }
            }

            .text-hint {
                font-size: 13px;
                text-align: center;
                margin: 30px 0 40px 0;
                color: #777;
                margin: 0;
            }

            .btn-purchase {
                margin-top: 40px;
            }
        }

        .card-credits {
            border: 1px solid #E8E8EE;
            padding: 12px 50px;
            border-radius: 6px;
            margin-bottom: 8px;
            height: 38px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
                margin-bottom: 0;
            }

            &.input-box {
                padding: 12px 20px;

                .text-label {
                    display: flex;
                    flex: 1;
                }

                input {
                    border: 0;
                    width: 100px;
                    text-align: right;
                }
            }

            .text-label {
                display: inline-block;
                font-weight: 500;
                color: #000;
                font-size: 16px;
            }

            .text-hint {
                display: inline-block;
                font-size: 12px;
                color: #777;
                font-weight: normal;
                padding: 0 8px;
            }

            i {
                display: none;
                position: absolute;
                right: 15px;
                font-size: 22px;
                color: #{$primary}
            }

            &.selected {
                border: 1px solid #{$color-primary};

                i {
                    display: block;
                }
            }
        }

        .card-credits-rows {
            .card-credits {
                margin: 0;
                border-bottom: 0;
    
                .text-label.highlighted {
                    font-size: 12px;
                    background: #00b38a;
                    padding: 2px 10px;
                    border-radius: 20px;
                    color: #FFF;
                    text-align: center;
                    min-width: 130px;
                }
                .spacer {
                    flex: 1;
                }
    
                &:first-child {
                    border-radius: 6px 6px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 6px 6px;
                    margin-bottom: 30px;
                    border-bottom: 1px solid #EEE;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .pricing-box {
            .row>div:first-child {
                justify-content: flex-end !important;
            }

            .row>div:last-child {
                justify-content: flex-start !important;
            }
        }
    }

    @media (max-width: 767px) {
        .pricing-box {
            .row>div {
                justify-content: center !important;
            }
        }

        .input-box {

            .text-label,
            input {
                font-size: 12px !important;
            }
        }

        .card-text {
            .text-label {
                font-size: 12px !important;
            }
        }
    }

    #footer {
        p {
            text-align: left;
        }
    }
}